<template>
  <div id="app" :class="{ 'theme-dark': nightMode }">
    <div class="container">
      <nav>
        <i
          class="fas fa-moon icon-moon"
          v-if="nightMode"
          @click="nightMode = !nightMode"
        ></i>
        <i
          class="fas fa-sun icon-sun"
          v-else
          @click="nightMode = !nightMode"
        ></i>
        <ul>
          <li><a href="#footer">Contact</a></li>
        </ul>
      </nav>

      <article>
        <img src="./assets/logo.png" alt="" />
        <p class="top">digital products that support your brand</p>
      </article>

      <article>
        <a target="blank" href="https://rails-todo-web-app.herokuapp.com/" rel="noopener">
          <img src="./assets/to-do-list.png" alt="" />
          <!-- <img src="./assets/slg-white.png" alt="" v-else /> -->
          <p>
            To-Do List web app (Ruby on Rails, Bootstrap, Postgres)
          </p>
        </a>
      </article>

      <article>
        <a target="blank" href="https://simplelifegear.com" rel="noopener">
          <img src="./assets/slg-black.png" alt="" v-if="!nightMode" />
          <img src="./assets/slg-white.png" alt="" v-else />
          <p>
            Branded e-commerce store (Wordpress with image slider, embedded
            video, logo design)
          </p>
        </a>
      </article>

      <article>
        <a target="blank" href="https://codepen.io/nthpower/pen/XWgjbLZ" rel="noopener">
          <img src="./assets/chatbot.gif" alt="" />
          <p>Chatbot/Virtual Assistant (HTML, CSS, JavaScript)</p>
        </a>
      </article>

      <article>
        <a target="blank" href="https://sassbackmedia.com" rel="noopener">
          <img src="./assets/trivia-jam.png" alt="" />
          <p>Web app (HTML, CSS, JavaScript with JSON API)</p>
        </a>
      </article>

      <article>
        <a target="blank" href="https://karaipantsu.com" rel="noopener">
          <img src="./assets/karai-pantsu.png" alt="" />
          <p>
            Tutorial website solution (Wordpress with custom JavaScript, dark
            mode variation, RSS)
          </p>
        </a>
      </article>
    </div>

    <footer id="footer">
      <div class="footer-content">
        <h3><a href="#top">Nth Power Designs</a></h3>
        <p>
          For questions, comments, or quotes on projects, please contact us any
          time
        </p>
        <ul class="socials">
          <li>
            <a href="mailto:info@nthpowerdesigns.com" rel="noopener"
              ><i class="fa fa-envelope"></i
            ></a>
          </li>
          <li>
            <a href="tel:+16475769657" rel="noopener"
              ><i class="fas fa-mobile-alt"></i
            ></a>
          </li>
          <li>
            <a href="https://codepen.io/nthpower" rel="noopener" target="_blank"
              ><i class="fa fa-codepen"></i
            ></a>
          </li>
        </ul>
      </div>

      <div class="footer-bottom">
        <p>
          <a href="#" @click="showPrivacyPolicy()">Privacy Policy</a> •
          <a href="#" @click="showTermsOfService()">Terms of Service</a>
        </p>
        <p>Copyright &copy; {{ new Date().getFullYear() }} Nth Power Designs</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      nightMode: false,
    };
  },
  watch: {
    nightMode: function () {
      localStorage.setItem("nightMode", JSON.stringify(this.nightMode));
    },
  },
  created() {
    this.nightMode = JSON.parse(localStorage.getItem("nightMode"));
  },
  methods: {
    showPrivacyPolicy() {
      window.open("/PrivacyPolicy.pdf", "_blank");
    },
    showTermsOfService() {
      window.open("/TOS.pdf", "_blank");
    },
  },
};
</script>


<style lang="scss">
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
}

#app {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  width: calc(100% - 20px);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.5s ease-in-out;
  color: #333;
  background-color: #efefef;

  a,
  a:visited,
  a:active {
    color: inherit;
    text-decoration: none;
  }

  a:hover {
    color: hotpink;
  }

  .container {
    max-width: 768px;
    margin-bottom: 500px;

    nav {
      position: relative;
      height: 50px;

      i {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 1.6rem;
        cursor: pointer;
      }

      .icon-moon {
        color: #99ccff;
      }

      ul {
        list-style-type: none;

        li {
          position: absolute;
          font-size: 1rem;
          font-weight: bold;
          top: 15px;
          left: 10px;
          cursor: pointer;
        }
      }
    }

    article {
      position: relative;
      padding: 10px;
      background-color: #fff;
      border: 1px solid #ccc;
      transition: 0.3s;

      a:hover {
        opacity: 0.5;
      }

      img {
        max-width: 100%;
        height: auto;
      }

      p {
        padding: 10px;

        &.top {
          font-size: 18px;
          text-shadow: 1px 1px 2px #f706cf;
        }
      }
    }
  }

  footer {
    position: absolute;
    bottom: 10px;
    background: #aaaaaa;
    min-width: calc(100% - 20px);
    font-family: "Open Sans";
    padding-top: 40px;
    color: #fff;
    align-content: center;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .footer-content {
    display: inline-block;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .footer-content h3 {
      font-size: 1.8rem;
      font-weight: 400;
      text-transform: capitalize;
      line-height: 3rem;
    }
    .footer-content p {
      margin: 10px;
      line-height: 28px;
      font-size: 14px;
    }
    .socials {
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2rem 0 3rem 0;
    }
    .socials li {
      margin: 0 10px;
    }
    .socials a {
      text-decoration: none;
      color: #fff;
    }
    .socials a i {
      font-size: 1.1rem;
      transition: color 0.4s ease;
    }
    .socials a:hover i {
      color: hotpink;
    }
  }

  .footer-bottom {
    background: #9a9a9a;
    padding: 20px 0;
    text-align: center;
  }
  .footer-bottom p {
    font-size: 14px;
    word-spacing: 2px;
    text-transform: capitalize;
  }

  &.theme-dark {
    color: #fff;
    background-color: #333;

    article {
      background-color: #222;
      border: 1px solid #555;

      p.top {
        text-shadow: 2px 2px #f706cf;
      }
    }

    footer {
      background: #111;
    }

    .footer-bottom {
      background: #000;
    }
  }
}
</style>